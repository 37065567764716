import React, { useEffect } from 'react';

const PhotoPage: React.FC = () => {
    useEffect(() => {
        window.location.href = 'https://snapchat.com/t/gzLW0xP0';
    }, []);

    return (
        <div>
            Redirecting...
        </div>
    );
};

export default PhotoPage;