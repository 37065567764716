import React from "react";
import { useEffect, useState } from 'react';
import DateTimeDisplay from "./DateTimeDisplay";


const useCountdown = () => {
    const countDownDate = new Date(2024, 7, 3, 14, 0, 0, 0).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};

export default function CountDownTimer() {
    const [days, hours, minutes, seconds] = useCountdown();
    return (
        <div className="countdown-container">
            <div className="countdown-element">
                <DateTimeDisplay value={days} type={'Dager'} isDanger={days <= 3}/>
            </div>
            <div className="countdown-element">
                <p>:</p>
            </div>
            <div className="countdown-element">
                <DateTimeDisplay value={hours} type={'Timer'} isDanger={false}/>
            </div>
            <div className="countdown-element">
                <p>:</p>
            </div>
            <div className="countdown-element">
                <DateTimeDisplay value={minutes} type={'Minutter'} isDanger={false}/>
            </div>
            <div className="countdown-element">
                <p>:</p>
            </div>
            <div className="countdown-element">
                <DateTimeDisplay value={seconds} type={'Sekunder'} isDanger={false}/>
            </div>
        </div>
    );
}