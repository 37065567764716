import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Paper} from "@mui/material";

export default function Gifts() {
    return (
        <Paper className={"info-body"}>
            <Typography variant="h5" gutterBottom>
                Gaver
            </Typography>
            <Typography variant="body1" gutterBottom>
                Selv om deres tilstedeværelse er den største gaven vi kan få, har noen spurt om gaveønsker.
                Hvis dere ønsker å gi oss en gave, vil vi sette stor pris på pengegaver som vi kan bruke til
                elsykler og friluftsutstyr. Skulle dere likevel foretrekke å gi en fysisk gave,
                kan dere se vår ønskeliste her

                Tusen takk for at dere deler denne spesielle dagen med oss!
                <br/><br/>
                <a style={{color:'white'}} title={'kitchen'} href={'https://www.kitchn.no/bryllup/vis-liste/160844/ '}>Link</a>
            </Typography>
        </Paper>
    );
}
