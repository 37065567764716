import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import oss from './images/img.png';
import './style/App.scss';
import AppBar from "./components/AppBar";
import Rsvp from "./components/pages/rsvp/RSVP";
import PracticalInfo from "./components/pages/PracticalInfo";
import Home from "./components/pages/Home";
import HowItStarted from "./components/pages/HowItStarted";
import FormPropsTextFields from "./components/pages/rsvp/RSVP";
import ResponsiveAppBar from "./components/AppBar";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PhotoPage from "./components/pages/PhotoPage";
import Gifts from "./components/pages/Gifts";
import Spotify from './components/pages/Spotify';

function App() {
    const [currentPage, setCurrentPage] = useState('Hjem');
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <ResponsiveAppBar setCurrentPage={setCurrentPage}/>
                <Routes>
                    <Route path="/photo" element={<PhotoPage />} />
                    <Route path="/praktisk-info" element={<PracticalInfo />} />
                    <Route path="/rsvp" element={<Rsvp />} />
                    <Route path="/gifts" element={<Gifts />} />
                    <Route path='/spotify' element={<Spotify/>} />
                    <Route path="/" element={<Home />} />
                    {/* Add more routes as needed */}
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;