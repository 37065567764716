import * as React from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, MenuItem, Container, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom'; // Step 1: Import useNavigate

interface ResponsiveAppBarProps {
    setCurrentPage: (page: string) => void;
}

const pages = [
    { name: 'Hjem', path: '/' },
    //{ name: 'Om oss', path: '/about' },
    { name: 'Praktisk info', path: '/praktisk-info' },
    //{ name: 'For tilreisende', path: '/for-tilreisende' },
    { name: 'Ønskeliste', path: '/gifts' },
    //{ name: 'Bilder av brudeparet', path: '/bilder-av-brudeparet' },
    //{ name: 'Kontakt oss', path: '/kontakt-oss' }
    { name: 'Bildeopplastning', path: '/photo' },
    { name: 'Spotify festliste', path: '/spotify'}
];

const ResponsiveAppBar: React.FC<ResponsiveAppBarProps> = ({ setCurrentPage }) => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate(); // Step 2: Initialize useNavigate

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handlePageChange = (page: { name: string; path: string }) => {
        setCurrentPage(page.name);
        navigate(page.path); // Step 3: Use navigate to change the path
        handleCloseNavMenu();
    };

    return (
        <AppBar position="sticky" elevation={0} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton size="large" aria-label="menu" onClick={handleOpenNavMenu} color="inherit">
                            <MenuIcon />
                        </IconButton>
                        <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                              keepMounted transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                              open={Boolean(anchorElNav)} onClose={handleCloseNavMenu}
                              sx={{ display: { xs: 'block', md: 'none' }, }}>
                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={() => handlePageChange(page)}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography variant="h5" noWrap component="a" href="" sx={{
                        mr: 2, display: { xs: 'flex', md: 'none' }, flexGrow: 1, fontFamily: 'monospace',
                        fontWeight: 700, letterSpacing: '.3rem', color: 'inherit', textDecoration: 'none',
                    }}>
                        Miriam og Martin gifter seg
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page.name}
                                onClick={() => handlePageChange(page)}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default ResponsiveAppBar;
