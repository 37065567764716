import * as React from 'react';
import {Paper, FormControlLabel} from '@mui/material';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import {Guest, addGuest} from "./persist";

export default function FormPropsTextFields() {
    const [deltar, setDeltar] = React.useState('');
    const [medFølge, setMedFølge] = React.useState(false);
    const [gjestTilMiddag, setGjestTilMiddag] = React.useState(false);
    const [følgeTilMiddag, setFølgeTilMiddag] = React.useState(false);
    const [gjestTilKaffe, setGjestTilKaffe] = React.useState(false);
    const [følgeTilKaffe, setFølgeTilKaffe] = React.useState(false);

    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [plusOneFirstName, setPlusOneFirstName] = React.useState('');
    const [plusOneLastName, setPlusOneLastName] = React.useState('');
    const [notes, setNotes] = React.useState('');

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    };

    const handlePlusOneFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPlusOneFirstName(event.target.value);
    };
    const handlePlusOneLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPlusOneLastName(event.target.value);
    }

    const handleChoiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeltar(event.target.name);
    };
    const handleGjestMiddagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGjestTilMiddag(event.target.checked);
        setGjestTilKaffe(event.target.checked)
    };
    const handlefølgeMiddagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFølgeTilMiddag(event.target.checked);
        setFølgeTilKaffe(event.target.checked)
    };
    const handleFølgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMedFølge(event.target.checked);
    }
    const handleGjestKaffeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGjestTilKaffe(event.target.checked);
    };

    const handleFølgeKaffeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFølgeTilKaffe(event.target.checked);
    };

    const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(event.target.value);
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const guest: Guest = {
            firstName,
            lastName,
            attending: deltar === 'deltar',
            plusOne: medFølge,
            attendingDinner: gjestTilMiddag,
            attendingCoffee: gjestTilKaffe,
            plusOneFirstName: plusOneFirstName,
            plusOneLastName: plusOneLastName,
            plusOneAttendingDinner: følgeTilMiddag,
            plusOneAttendingCoffee: følgeTilKaffe,
            notes: notes
        };


        const success = await addGuest(guest);

        if (success) {
            alert('Innsending vellykket!'); // Display popup on success
            // Reset all fields
            setDeltar('');
            setMedFølge(false);
            setGjestTilMiddag(false);
            setFølgeTilMiddag(false);
            setGjestTilKaffe(false);
            setFølgeTilKaffe(false);
            setFirstName('');
            setLastName('');
            setPlusOneFirstName('');
            setPlusOneLastName('');
            setNotes('');
        } else {
            alert("Noe gikk galt. Vennligst prøv igjen senere.")
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Paper className={"rsvp-form"}>
                <h1>
                    Påmelding
                </h1>
                <p>
                    Vennligst gi ditt svar innen 1. mai 2024. Ved endring av svar, vennligst ta kontakt med oss snarest.
                </p>
                <div>
                    <FormControlLabel
                        control={<Checkbox checked={deltar === 'deltar'} onChange={handleChoiceChange} name="deltar"/>}
                        label="Vi ses i bryllupet!"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={deltar === 'avslår'} onChange={handleChoiceChange} name="avslår"/>}
                        label="Skåler langveis fra"
                    />
                </div>
                {deltar === 'deltar' && (
                    <div>
                        <div>
                            <TextField
                                required
                                id="standard-required"
                                label="Fornavn"
                                variant="standard"
                                value={firstName}
                                onChange={handleFirstNameChange}
                            />
                            <TextField
                                required
                                id="standard-required"
                                label="Etternavn"
                                variant="standard"
                                value={lastName}
                                onChange={handleLastNameChange}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={gjestTilMiddag} onChange={handleGjestMiddagChange}
                                                   name="middag"/>}
                                label="Til middag"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={gjestTilMiddag || gjestTilKaffe}
                                                   onChange={handleGjestKaffeChange}
                                                   disabled={gjestTilMiddag} name="kaffe"/>}
                                label="Til kaffe og fest"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={medFølge === true} onChange={handleFølgeChange}
                                                   name="medFølge"/>}
                                label="Med følge"
                            />
                        </div>

                        {medFølge && (
                            <div>
                                <TextField
                                    required
                                    id="standard-required"
                                    label="Fornavn"
                                    variant="standard"
                                    value={plusOneFirstName}
                                    onChange={handlePlusOneFirstNameChange}
                                />
                                <TextField
                                    required
                                    id="standard-required"
                                    label="Etternavn"
                                    variant="standard"
                                    value={plusOneLastName}
                                    onChange={handlePlusOneLastNameChange}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={følgeTilMiddag} onChange={handlefølgeMiddagChange}
                                                       name="middag"/>}
                                    label="Til middag"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={følgeTilMiddag || følgeTilKaffe}
                                                       onChange={handleFølgeKaffeChange} disabled={følgeTilMiddag}
                                                       name="kaffe"/>}
                                    label="Til kaffe og fest"
                                />


                            </div>
                        )}
                        <div>
                            <TextField
                                id="standard-multiline-static"
                                label="Allergier, barn eller annet vi bør vite om?"
                                value={notes}
                                onChange={handleNotesChange}
                            />
                        </div>
                    </div>
                )}
                {deltar === 'avslår' && (
                    <div>
                        <p>
                            Synd du ikke har mulighet til å komme. Takk for at du melder ifra. Det holder at en av dere
                            svarer.
                        </p>
                        <div>
                            <div>
                                <TextField
                                    required
                                    id="standard-required"
                                    label="Fornavn"
                                    variant="standard"
                                    value={firstName}
                                    onChange={handleFirstNameChange}
                                />
                                <TextField
                                    required
                                    id="standard-required"
                                    label="Etternavn"
                                    variant="standard"
                                    value={lastName}
                                    onChange={handleLastNameChange}
                                />
                            </div>
                            <div>
                                <TextField
                                    id="standard-multiline-static"
                                    label="Sende inn en hilsen?"
                                    value={notes}
                                    onChange={handleNotesChange}
                                />
                            </div>

                        </div>
                    </div>
                )}
                {deltar !== '' && (
                    <Button variant="contained" endIcon={<SendIcon/>} type="submit">
                        Send
                    </Button>
                )}
            </Paper>
        </form>
    );
}