import React, { useEffect } from 'react';

const Spotify: React.FC = () => {
    useEffect(() => {
        window.location.href = 'https://open.spotify.com/playlist/1R4JP9OvyOuGoueWNtLdKp?si=ac25f78c4a8e4d27&pt=95cce63a871ca8899e65d490f57ab2bc';
    }, []);

    return (
        <div>
            Redirecting...
        </div>
    );
};

export default Spotify;