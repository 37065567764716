// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {collection, getDocs, getFirestore, addDoc} from "@firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC-TPt65Z9ZRlGtTl5xa1UKCEySnKNr0_Q",
    authDomain: "miriamelskermartin.firebaseapp.com",
    projectId: "miriamelskermartin",
    storageBucket: "miriamelskermartin.appspot.com",
    messagingSenderId: "865885809618",
    appId: "1:865885809618:web:ad69e2fdf79ccbec7a360b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

// Get a list of guests from your database

export async function getGuests() {
    const guestcol = collection(db, 'guests');
    const guestSnapshot = await getDocs(guestcol);
    const guestsList = guestSnapshot.docs.map(doc => doc.data());
    return guestsList;
}


export type Guest = {
    firstName: string;
    lastName: string;
    attending: boolean;
    attendingDinner: boolean;
    attendingCoffee: boolean;
    plusOne: boolean;
    plusOneFirstName: string;
    plusOneLastName: string;
    plusOneAttendingDinner: boolean;
    plusOneAttendingCoffee: boolean;
    notes: string;
};

export async function addGuest(guest: Guest) {
    try {
        await addDoc(collection(db, "guests"), guest);
        return true
    } catch (e) {
        console.error("Error adding document: ", e);
        return false
    }
}
