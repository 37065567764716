import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Paper} from "@mui/material";

export default function PracticalInfo() {
    return (
        <Paper className={"info-body"}>
            <Typography variant="h5" gutterBottom>
                Program for dagen
            </Typography>
            <Typography variant="body1" gutterBottom>
                Vielsen avholdes i Hjørundfjord kirke kl 14.00. Kirken ligger på Sæbø i Ørsta kommune.
                Etter vielsen drar vi til Sagafjord hotell hvor det blir servert bryllupskake og en kaffe.
                Det blir da mulighet for mingling før forretten serveres ca kl 16.30.
                Etter hovedretten vil det bli en liten pause så de med barn har mulighet til å legge disse.
                Til bryllupsfesten utpå kvelden kommer det litt flere gjester.
                Vi tar forbehold om endringer i programmet.
            </Typography>
            <Typography variant="h5" gutterBottom>
                Toastmaster
            </Typography>
            <Typography variant="body1" gutterBottom>
                Kim Thorup og Ann Helen Ose Thorup er toastmastere og vil holde kontroll på dagens program.
                Vi ber om at alle som ønsker å tale gir beskjed om dette i god tid før bryllupet.
                Taletid for nær familie er 10 minutter, mens for andre vil det være en makstid på fem minutter.
                Ann Helen Ose Thorup: 911 95 472
                Kim Thorup: 970 76 411
            </Typography>
            <Typography variant="h5" gutterBottom>
                Følge
            </Typography>
            <Typography variant="body1" gutterBottom>
                Vi har mange kjente og kjære og skulle gjerne invitert alle mann med følge, men på grunn av plassbegrensning hos Sagafjord har det vært en hard prioritering.
                Om det kun er ditt navn som står på konvolutten så er det kun du som er invitert til middag.
                I så tilfelle er partner invitert til kaffi og fest, og kan komme da.
            </Typography>
            <Typography variant="h5" gutterBottom>
                Barn
            </Typography>
            <Typography variant="body1" gutterBottom>
                Bryllupet vil være barnevennlig, men med noen forbehold.
                Det vil serveres alkohol i bryllupet. Vi vil lage til et lekehjørne med leker for små barn som kan
                benyttes hele dagen.
                Mellom 1830-1930 tar vi en pause fra middagen for å gi rom for legging.
                Programmet vil fortsette kl 1930.
            </Typography>
            <Typography variant="h5" gutterBottom>
                Overnatting
            </Typography>
            <Typography variant="body1" gutterBottom>
                Vi vil gjerne ha så mye tid med gjestene våre som mulig og håper dere har mulighet til å overnatte på Sagafjord hotell sammen med oss.
                Vi spiser en god frokost dagen etter bryllupet og legger til rette for badstu i Sæbø fjordsauna etter frokosten.
                Dersom du booker rom mer enn tre måneder før bryllupet får du 15% rabatt.
                Du kan ringe direkte til hotellet for å reservere rom. Sagafjord har både enkeltrom, dobbeltrom og familierom tilgjengelig.
                Det er også mulig å sove på hotellet fra fredag til lørdag dersom dere ankommer Ørsta på fredagen, men Sæbø har også andre overnattingstilbud.
            </Typography>
        </Paper>
    );
}
