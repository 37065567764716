import React from "react";
import {Paper} from "@mui/material";
import CountDownTimer from "../molecules/countdown";
import Box from "@mui/material/Box";


export default function Home() {
    return (
        <Box>
            <CountDownTimer/>
            <Paper className={'home-body'}>
                <h1>
                    Velkommen til bryllupet vårt!
                </h1>
                <p>
                    Vi gifter oss 03.08.2024! Hjørundfjord kirke | Ørsta
                </p>
                <p>
                    Vi gleder oss til å feire dagen sammen med dere! Tenk å få samlet alle de vi er mest glad i på en og
                    samme dag!
                </p>
                <p>
                    Nettsiden er fortsatt under utvikling, men noen sider er gjort tilgjengelige for å dele litt informasjon. Følg med for oppdateringer!
                </p>
            </Paper>
        </Box>
    )
}